<script>
    import { onMount, getContext } from 'svelte';
    import Lottie from 'components/Lottie';

    export let title;
    export let message;
    export let promise = null;
    export let onSuccess = () => {};
    export let onError = () => {};
    export let onClose = () => {};

    const { close } = getContext('simple-modal');

    onMount(() => {
        setTimeout(() => {
            if (promise) {
                promise.then(
                    (data) => {
                        handleClose(onSuccess, data);
                    },
                    (error) => {
                        handleClose(onError, error);
                    },
                );
            } else {
                handleClose();
            }
        }, 1500);
    });

    function handleClose(callback, result) {
        close();
        callback(result);
        onClose();
    }
</script>

<div class="modal">
    <div class="modal-content">
        <div class="modal-content__loader">
            <Lottie jsonPath="lottie/loading-circle.json" />
        </div>
        <h2 class="modal-content__title">{title}</h2>
        <p class="modal-content__desc">
            {@html message}
        </p>
    </div>
    <!-- <div class="actions">
        <Button primary onClick={handleClose}>{confirmLabel}</Button>
    </div> -->
</div>

<style lang="scss">.modal-content {
  margin-bottom: 3.75em;
  padding: 2.8125em 2.8125em 0 2.8125em;
  border-radius: 50px;
  text-align: center;
}
.modal-content__loader {
  margin: 0 auto;
  margin-bottom: 1.5625em;
  width: 100px;
  height: 100px;
}
.modal-content__title {
  margin-bottom: 1.5625em;
  font-size: 1.25em;
  font-weight: 700;
  color: #5123ad;
  line-height: 1.25;
}
.modal-content__desc {
  font-size: 1em;
  color: #9f9fa5;
  line-height: 1.25;
}
.modal-content :global(p) {
  margin-bottom: 1.5625em;
}</style>
