<script>
    import { getContext } from 'svelte';
    import Button from 'components/Button';

    export let onClose = () => {};

    const { close } = getContext('simple-modal');

    function handleClose() {
        onClose();
        close();
    }
</script>

<div class="modal">
    <div class="modal-content">
        <h2 class="modal-content__title">Contact us</h2>
        <div class="modal__desc">
            <p>Term Finance is a web-based lender operating across the Caribbean.</p>
            <div class="contacts">
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in Trinidad & Tobago</div>
                    <div class="contacts__number">1-868-235-5650</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in Barbados</div>
                    <div class="contacts__number">1-246-428-8376</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in Guyana</div>
                    <div class="contacts__number">1-592-608-8376</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in Jamaica</div>
                    <div class="contacts__number">1-876-891-8376</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in St. Lucia</div>
                    <div class="contacts__number">1-758-484-8376</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Email us</div>
                    <a href="mailto:info@mytermfinance.com" class="contacts__number">info@mytermfinance.com</a>
                </div>
            </div>
        </div>
    </div>
    <div class="actions">
        <Button primary small onClick={handleClose}>OK</Button>
    </div>
</div>

<style lang="scss">.modal-content {
  margin-bottom: 1.875em;
  padding: 1.875em 1.875em 0 1.875em;
}
.modal-content__title {
  margin-bottom: 1.5625em;
  font-size: 1.25em;
  font-weight: 700;
  color: #5123ad;
  line-height: 1.25;
}
.modal-content :global(p) {
  margin-bottom: 1.5625em;
}

.contacts {
  display: grid;
  justify-content: flex-start;
  grid-template-columns: 1fr 1fr;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 1.875em;
}
.contacts__contact {
  width: 100%;
  margin: 1.25em 0;
  font-size: 0.875em;
}
.contacts__name {
  margin-bottom: 0.625em;
  color: #000;
}
.contacts__number {
  font-weight: 700;
  color: #5123ad;
}

.actions {
  padding: 0.625em 1.5625em;
  border-radius: 0 0 1.5625em 1.5625em;
  background: #fbfbfb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}</style>
