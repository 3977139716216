<script>
    import { getContext } from 'svelte';

    import Button from 'components/Button';
    import Loader from 'components/Loader';
    import Lottie from 'components/Lottie';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';

    export let trigger = false;
    export let title = 'Loading ...';
    export let cancelLabel = 'Cancel';
    export let submitLabel = 'Submit';
    export let loadingLabel = 'Loading';
    export let autoSubmit = false;
    export let canSubmit = true;
    export let closeOnSubmit = true;
    export let customActions = [];
    export let onClose = () => {};
    export let onError = () => {};
    export let onCancel = () => {
        return Promise.resolve(true);
    };
    export let onSubmit = () => {
        return Promise.resolve(true);
    };

    const { open } = getContext('simple-modal');

    let isClosing = false;
    let isLoading = false;
    let isSuccessful = false;

    $: {
        if (closeOnSubmit) {
            closeOnSubmit = closeOnSubmit;
        }

        if (canSubmit && autoSubmit) {
            handleSubmit();
        }
    }

    function close(delay = 1000) {
        isClosing = true;
        trigger = false;
        onClose();

        setTimeout(() => {
            isClosing = false;
            isLoading = false;
            isSuccessful = false;
        }, delay);
    }

    function handleSubmit() {
        isLoading = true;

        onSubmit().then(
            () => {
                isSuccessful = true;
                setTimeout(close, 2000);
            },
            (error) => {
                close();
                onError();
                setTimeout(() => {
                    open(
                        AlertModal,
                        {
                            title: 'Something went wrong.',
                            message:
                                'We were unable to process your request. Please try again later or contact support for help.',
                        },
                        alertModalOptions,
                    );
                }, 1000);
            },
        );
    }

    function handleCancel() {
        onCancel().finally(() => {
            close();
        });
    }
</script>

{#if trigger || isClosing}
    <div
        class="root animate__animated animate__fadeIn"
        class:animate__fadeOut={isClosing}
        class:root--closing={isClosing}
    >
        <div
            class="overlay animate__animated animate__slideInUp"
            class:animate__slideOutDown={isClosing}
            class:overlay--closing={isClosing}
        >
            <div class="header">
                <div>
                    <div class="header__title">{title}</div>
                </div>
                <div class="actions actions--mobile">
                    <Button cancel small disabled={isLoading} onClick={() => handleCancel()}>{cancelLabel}</Button>
                    {#each customActions as action}
                        <Button
                            primary
                            small
                            disabled={!action.isEnabled}
                            onClick={() => {
                                action.action();
                            }}
                        >
                            {action.label}
                        </Button>
                    {/each}
                    {#if canSubmit}
                        <div class="animate__animated animate__bounceIn">
                            <Button primary small disabled={isLoading || !canSubmit} onClick={() => handleSubmit()}>
                                {submitLabel}
                            </Button>
                        </div>
                    {/if}
                </div>
            </div>
            <div class="content">
                {#if isLoading}
                    <div class="loader animate animate__fadeIn">
                        {#if !isSuccessful}
                            <Loader title={loadingLabel} subtitle="This might take a few seconds ..." />
                        {:else}
                            <div class="loader__success animate__animated animate__bounceIn">
                                <Lottie jsonPath="lottie/docs-uploaded.json" loop={false} />
                            </div>
                        {/if}
                    </div>
                {/if}
                <slot name="content" />
            </div>
            <div class="actions">
                <Button cancel small disabled={isLoading} onClick={() => handleCancel()}>{cancelLabel}</Button>
                {#each customActions as action}
                    <Button
                        primary
                        small
                        disabled={!action.isEnabled}
                        onClick={() => {
                            action.action();
                        }}
                    >
                        {action.label}
                    </Button>
                {/each}
                {#if canSubmit}
                    <div class="animate__animated animate__bounceIn">
                        <Button primary small disabled={isLoading || !canSubmit} onClick={() => handleSubmit()}>
                            {isLoading ? 'Submitting ...' : submitLabel}
                        </Button>
                    </div>
                {/if}
            </div>
        </div>
    </div>
{/if}

<style lang="scss">.root {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  animation-duration: 0.25s;
}
.root--closing {
  animation-delay: 0.75s;
}

.overlay {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: #fff;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  animation-delay: 0.25s;
  animation-duration: 0.3s;
}
.overlay--closing {
  animation-delay: 0 !important;
}
@media (min-width: 1050px) {
  .overlay {
    width: 65%;
    height: 95vh;
    border-radius: 1.5625em 1.5625em 0 0;
  }
}

.header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.9375em 1.5625em;
  background: #fff;
  border-bottom: 1px solid #e1e1e3;
  border-radius: 1.5625em 1.5625em 0 0;
}
.header__title {
  margin-bottom: 0.625em;
  font-weight: 700;
  font-size: 1.125em;
  color: #5123ad;
}
@media (min-width: 650px) {
  .header {
    flex-flow: row nowrap;
    padding: 1.875em 1.5625em;
  }
  .header__title {
    margin-bottom: 0;
    font-size: 1.5em;
  }
}

.content {
  width: 100%;
  height: 100%;
  margin: 5.625em 0;
  padding: 0.625em 0.625em 12.5em 0.625em;
  color: #000;
  overflow-y: scroll;
}
@media (min-width: 650px) {
  .content {
    padding: 0.625em 1.5625em 12.5em 1.5625em;
  }
}

.actions {
  display: none;
  position: relative;
  z-index: 10;
  justify-content: flex-end;
  align-items: center;
  -moz-column-gap: 0.625em;
       column-gap: 0.625em;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f5f4ff;
  border-top: 1px solid #eeeeef;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.actions--mobile {
  display: flex;
  width: auto;
  position: relative;
  background: none;
  box-shadow: none;
  border-top: none;
  font-size: 0.875em;
}
@media (min-width: 1050px) {
  .actions {
    display: flex;
    padding: 0.9375em 1.5625em;
  }
  .actions--mobile {
    display: none;
  }
}

.loader {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.99);
}
.loader__success {
  width: 9.375em;
}</style>
