<script>
    import { onMount } from 'svelte';

    import authStore from 'stores/auth';
    import customerStore from 'stores/customer';

    import infinityService from 'services/infinity';

    import Loader from 'components/Loader';
    import PageOverlay from 'components/PageOverlay';

    export let trigger = false;
    export let loan = null;
    export let onSubmit = () => {};
    export let onClose = () => {};

    let title;
    let isLoadingAgreement = false;
    let loanAgreement;
    let loanAgreementSequence;
    let loanAgreementBase64;
    let currentSequenceItemIndex;
    let signature;
    let canSubmit = false;
    let autoSubmit = false;
    let customActions = [
        {
            label: 'Continue',
            isEnabled: canSubmit,
            action: () => {
                nextDocument();
            },
        },
    ];

    $: {
        if (loan && trigger) {
            infinityService
                .getLoanAgreementSequence({
                    customerId: $authStore.customerId,
                    loanId: loan.id,
                })
                .then((sequence) => {
                    loanAgreementSequence = sequence.sequenceItems;
                    nextDocument();
                });
        }
    }

    function reset() {
        loanAgreement = null;
        loanAgreementSequence = null;
        loanAgreementBase64 = null;
        currentSequenceItemIndex = null;
        signature = null;
        canSubmit = false;
        autoSubmit = false;
    }

    function nextDocument() {
        currentSequenceItemIndex = currentSequenceItemIndex == null ? 0 : currentSequenceItemIndex + 1;

        if (currentSequenceItemIndex < loanAgreementSequence.length) {
            let sequenceItem = loanAgreementSequence[currentSequenceItemIndex];
            title = sequenceItem.name + ' - ' + `${sequenceItem.key}/${loanAgreementSequence.length}`;
            signature = null;
            handleChangeSignature();
            return getLoanAgreement();
        } else {
            customActions = [];
            canSubmit = true;

            setTimeout(() => {
                autoSubmit = true;
            }, 1000);
        }
    }

    async function _onSubmit() {
        await infinityService.setLoanSigned({
            customerId: $authStore.customerId,
            loanId: loan.id,
            signature: $customerStore.fullName,
        });

        onSubmit();

        return Promise.resolve({});
    }

    function getLoanAgreement() {
        isLoadingAgreement = true;

        return infinityService
            .getLoanAgreement({
                customerId: $authStore.customerId,
                loanId: loan.id,
                sequenceItemKey: loanAgreementSequence[currentSequenceItemIndex].key,
                forSigning: true,
                forPrinting: false,
            })
            .then((agreement) => {
                loanAgreement = agreement.html;
                loanAgreementBase64 = 'data:application/pdf;base64,' + loanAgreement;
            })
            .finally(() => {
                isLoadingAgreement = false;
            });
    }

    function handleChangeSignature() {
        canSubmit = currentSequenceItemIndex == loanAgreementSequence.length;
        if (customActions && customActions[0]) {
            customActions[0].isEnabled = signature == $customerStore.fullName;
        }
    }

    function onCancel() {
        setTimeout(() => {
            reset();
        }, 1000);

        return Promise.resolve({});
    }
</script>

<PageOverlay
    {title}
    submitLabel="Submit"
    loadingLabel={'Signing ...'}
    {customActions}
    {trigger}
    {canSubmit}
    {autoSubmit}
    {onCancel}
    {onClose}
    onSubmit={_onSubmit}
>
    <div slot="content">
        {#if canSubmit}
            <div class="overlay">
                <Loader title="Submitting your documents ..." subtitle="This might take a few seconds." />
            </div>
        {:else if loan.canSeeLoanAgreement}
            {#if isLoadingAgreement}
                <div class="overlay">
                    <Loader title="Loading Documents" subtitle="This might take a few seconds ..." />
                </div>
            {:else if loanAgreement}
                <form class="signature animate__animated">
                    <div>
                        <label class="signature__label" for="signature">Signature</label>
                        <div class="signature__desc">
                            <p>
                                Please enter your name ({$customerStore.fullName}) as it appears on the loan document.
                            </p>
                            <p>
                                This is your digital signature and verifies that you agree to and accept the above
                                terms.
                            </p>
                        </div>
                        <input
                            type="text"
                            class="signature__input"
                            bind:value={signature}
                            placeholder="Sign Here"
                            on:input={handleChangeSignature}
                        />
                    </div>
                </form>
                <div class="agreement">
                    <div
                        class="animate__animated animate__fadeIn"
                        class:animate__fadeIn={!isLoadingAgreement}
                        class:animate__fadeOut={isLoadingAgreement}
                    >
                        {@html loanAgreement}
                    </div>
                </div>
            {/if}
        {:else}
            <div>Loan Agreement is still under review. Please check back later.</div>
        {/if}
    </div>
</PageOverlay>

<style lang="scss">.agreement {
  overflow: hidden;
  overflow-y: scroll;
}
@media (min-width: 650px) {
  .agreement {
    overflow: auto;
  }
}

.signature {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1.5625em 0;
  border: 2px solid #5123ad;
  border-radius: 5px;
  padding: 0.625em;
}
.signature__label {
  display: block;
  font-weight: 700;
}
.signature__desc {
  margin-bottom: 0.625em;
  font-size: 0.875em;
  line-height: 1.1;
  color: #9f9fa5;
}
.signature__input {
  padding: 0.3125em 0;
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #000;
  outline: 0;
  font-weight: 500;
  font-size: 1.125em;
  color: #000;
}
@media (min-width: 650px) {
  .signature {
    padding: 1.25em;
  }
}

.overlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1.5625em;
  background: rgba(255, 255, 255, 0.99);
}

:global(.signature) {
  display: none;
}

:global(small) {
  display: none;
}

:global(.BottomLine) {
  display: none;
}</style>
