<script>
    import { onMount, onDestroy, getContext } from 'svelte';
    import { link, push, location } from 'svelte-spa-router';

    import REGISTER_CONFIG from 'configs/register';

    import infinityService from 'services/infinity';

    import authStore from 'stores/auth';
    import registerStore from 'stores/register';
    import customerStore from 'stores/customer';

    import Page from 'components/Page';
    import NavBar from 'components/NavBar';
    import Button from 'components/Button';
    import Lottie from 'components/Lottie';
    import { SupportModal, supportModalOptions } from 'components/SupportModal';
    import { ForgotPasswordModal, forgotPasswordModalOptions } from 'components/ForgotPasswordModal';
    import analyticsService from 'services/analytics';

    const { open } = getContext('simple-modal');

    export let params = {};

    let isLogin;
    let isForgotPassword;
    let isAbout;
    let isFootball;
    let username, password, emailAddress, errorMsg;
    let isLoading = false;
    let activeSlideId;
    let slideshowInterval;
    let progressBarElement;

    const SLIDE_WAIT_TIME_SECONDS = 25;
    const SLIDES = [
        {
            id: 0,
            title: 'Term Finance',
            titleShort: 'TF',
            subtitle: 'Financial solutions for <span>employees</span> and <span>small businesses</span>',
            desc: 'Term Finance offers smart financial solutions to employees and small businesses. We own and operate various different brands across the Caribbean.',
            cta: {
                label: 'Learn More About Us',
                action: () => {
                    // push('/register');
                    isLogin = false;
                    setTimeout(() => (isAbout = true), isLogin ? 1000 : 0);
                },
            },
        },
        {
            id: 1,
            title: 'Loans',
            titleShort: 'Loans',
            subtitle: 'Get the right loan for your <span>family</span> or <span>small business</span>',
            desc: 'Term Finance provides responsible loans to employees and small businesses through a completely web-based system.',
            cta: {
                label: 'Apply Now',
                action: () => {
                    push('/register');
                },
            },
        },
        {
            id: 2,
            title: 'Shop Now, Pay Later',
            titleShort: 'Shop',
            subtitle: 'Get the products you need now and <span>pay over time</span>',
            desc: 'Term Finance provides product financing through an online shop with a great variety of consumer goods.',
            cta: {
                label: 'Shop Now',
                url: 'https://mytfshop.com',
            },
        },
        {
            id: 3,
            title: 'TF Auto',
            titleShort: 'Auto',
            subtitle:
                'Borrow against your <span>existing vehicle</span> or borrow to <span>purchase a new vehicle</span>',
            desc: 'TF SME provides loans to customers who have equity in their existing vehicles, or loans to customers who are interested in purchasing a new vehicle. This product is available in T&T only.',
            cta: {
                label: 'Apply Now',
                url: 'https://mytermfinance.com/#/register?country=tt&type=3',
            },
        },
        {
            id: 4,
            title: 'Term Finance City League',
            titleShort: 'Football',
            subtitle: 'Join us in shaping the <span>future of Caribbean football</span>',
            desc: 'Term Finance proudly sponsors the Term Finance City League, partnering with City F.C. to create a premier youth football program that develops talent while teaching discipline and integrity.',
            cta: {
                label: 'Learn More',
                action: () => {
                    isLogin = false;
                    setTimeout(() => (isFootball = true), isLogin ? 1000 : 0);
                },
            },
        },
        {
            id: 5,
            title: 'Financial Wellness',
            titleShort: 'Well',
            subtitle: 'Get financial guidance from <span>people who care</span>',
            desc: 'Term Finance offers Financial Wellness seminars and personal consultations to our customers at no charge.',
            cta: {
                label: 'Learn More',
                url: 'https://wellness.mytermfinance.com',
            },
        },
    ];

    onMount(() => {
        isAbout = false;
        isFootball = $location == '/football';
        isLogin = $location == '/login';
        isForgotPassword = $location == `/reset-password/${params.token}`;
        username = $registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].username;
        password = $registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].password;

        // Automatically log in the user if they just completed registering
        setTimeout(() => {
            if (username && password && $registerStore.isComplete) {
                $registerStore.reset();
                login();
            }
        }, 1000);

        // Start the slideshow
        activeSlideId = SLIDES[0].id;
        startSlideshow();

        // Show forgot password modal
        if (isForgotPassword) {
            open(ForgotPasswordModal, { token: params.token }, forgotPasswordModalOptions);
        }
    });

    onDestroy(() => {});

    function startSlideshow() {
        slideshowInterval = setInterval(changeSlide, SLIDE_WAIT_TIME_SECONDS * 1000);
    }

    function changeSlide(slideId = null) {
        let nextSlideId;

        if (slideId != null) {
            clearInterval(slideshowInterval);
            activeSlideId = slideId;
            resetProgressBarAnimation();
            startSlideshow();
        } else {
            nextSlideId = activeSlideId + 1;
            activeSlideId = SLIDES[nextSlideId >= SLIDES.length ? 0 : nextSlideId].id;
        }
    }

    function resetProgressBarAnimation() {
        progressBarElement.classList.remove('split__progress--fill');
        progressBarElement.offsetHeight; /* trigger reflow */
        progressBarElement.classList.add('split__progress--fill');
    }

    function login() {
        isLoading = true;
        errorMsg = null;

        infinityService.login({ username, password }).then(
            (response) => {
                if (response.isSuccessful) {
                    authStore.setCustomerId(response.customerId);

                    // Fetch the rest of the customer details
                    customerStore.fetch(response.customerId).then(
                        () => {
                            push('/loans');
                        },
                        () => {
                            errorMsg = 'We were not able to log you in. Please contact support if this issue persists.';
                            isLoading = false;
                        },
                    );
                } else {
                    errorMsg = response.errorMsg;
                    isLoading = false;
                }
            },
            (error) => {
                console.error(error);
                isLoading = false;
            },
        );
    }
</script>

<NavBar on:login={() => (isLogin = true)} on:football={() => (isFootball = true)} />
<div class="root">
    <div class="reveal animate__animated animate__slideOutLeft" />
    <Page>
        <div slot="content">
            <div class="split">
                <div class="split__container">
                    <div class="wrapper">
                        <div class="split__bg split__bg--overlay" />
                        {#each SLIDES as slide, i}
                            <div
                                class="split__bg split__bg--mobile animate__animated"
                                class:animate__fadeIn={activeSlideId == slide.id}
                                class:animate__fadeOut={activeSlideId != slide.id}
                                style={`background-image: url(/images/bg_${slide.id}.jpg); z-index: ${SLIDES.length - i};`}
                            />
                        {/each}
                        <!-- Login / Forgot Password -->
                        <div
                            class="slide-overlay slide-overlay--login animate__animated"
                            class:animate__slideInLeft={isLogin}
                            class:animate__slideOutLeft={!isLogin}
                        >
                            <div
                                class="slide slide--no-top slide--center animate__animated animate__fadeIn"
                                style="animation-delay: 1s;"
                            >
                                <div class="slide__content slide__content--compact">
                                    <div class="slide__header">
                                        <h1
                                            class="slide__title slide__title--no-margin slide__title--extra-line-height slide__title--light animate__animated animate__pulse"
                                        >
                                            Log in
                                        </h1>
                                        <div
                                            class="overlay-close-btn animate__animated animate__fadeIn"
                                            on:keydown
                                            on:click={() => {
                                                isLogin = false;
                                            }}
                                        >
                                            <Lottie jsonPath="/lottie/close_icon.json" loop={false} />
                                        </div>
                                    </div>
                                    <form class="login-form">
                                        {#if errorMsg}
                                            <div class="login-form__errors">{errorMsg}</div>
                                        {/if}
                                        {#if !isLoading}
                                            <div class="login-form__control">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    class="login-form__input"
                                                    placeholder="Username"
                                                    bind:value={username}
                                                />
                                            </div>
                                            <div class="login-form__control">
                                                <input
                                                    autocomplete="off"
                                                    class="login-form__input"
                                                    type="password"
                                                    placeholder="Password"
                                                    bind:value={password}
                                                />
                                            </div>
                                        {:else}
                                            <div class="login-form__control animate__animated animate__bounceIn">
                                                <div class="login-form__loader">
                                                    <Lottie jsonPath="/lottie/loading-circle.json" />
                                                </div>
                                            </div>
                                        {/if}
                                        <div class="login-form__actions">
                                            <Button
                                                type="submit"
                                                primaryInverse
                                                fullWidth
                                                onClick={() => login()}
                                                isLoading
                                                disabled={isLoading || !username || !password}
                                            >
                                                {isLoading ? 'Logging in ...' : 'Log in'}
                                            </Button>
                                        </div>
                                        <div class="login-form__links">
                                            <div class="login-form__link">
                                                <div
                                                    on:keydown
                                                    on:click={() => {
                                                        open(ForgotPasswordModal, {}, forgotPasswordModalOptions);
                                                    }}
                                                >
                                                    I forgot my password
                                                </div>
                                            </div>
                                            <div class="login-form__link">
                                                Not a member?
                                                <a use:link href="/register">Register Today</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- TF City Leauge -->
                        <div
                            class="slide-overlay slide-overlay--football animate__animated"
                            class:animate__slideInLeft={isFootball}
                            class:animate__slideOutLeft={!isFootball}
                        >
                            <div
                                class="slide slide--no-top slide--center animate__animated animate__fadeIn"
                                style="animation-delay: 1s;"
                            >
                                <div class="slide__content slide__content--compact slide__content--about">
                                    <div class="slide__header">
                                        <h1
                                            class="slide__title slide__title--no-margin slide__title--light slide__title--extra-line-height"
                                        >
                                            Term Finance City League
                                        </h1>
                                        <div
                                            class="overlay-close-btn animate__animated animate__fadeIn"
                                            on:keydown
                                            on:click={() => (isFootball = false)}
                                        >
                                            <Lottie jsonPath="/lottie/close_icon.json" loop={false} />
                                        </div>
                                    </div>
                                    <p class="slide-overlay__p">
                                        Term Finance proudly sponsors the Term Finance City League, partnering with City
                                        F.C. to create a premier youth football program that develops talent while
                                        teaching discipline and integrity. Player registrations are available through
                                        our website, streamlining the process while supporting league operations.
                                    </p>
                                    <p class="slide-overlay__p">
                                        Join us in shaping the future of Caribbean football by registering today for
                                        this exciting tournament that nurtures young athletes both on and off the field!
                                    </p>
                                    <br />
                                    <Button
                                        primary
                                        medium
                                        onClick={() => {
                                            analyticsService.track(analyticsService.EVENTS.FOOTBALL_REGISTER, {});
                                            window.open('https://forms.gle/de4MS8LNNDjqVHQ49');
                                        }}
                                    >
                                        Register Today
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <!-- About -->
                        <div
                            class="slide-overlay slide-overlay--about animate__animated"
                            class:animate__slideInLeft={isAbout}
                            class:animate__slideOutLeft={!isAbout}
                        >
                            <div
                                class="slide slide--no-top slide--center animate__animated animate__fadeIn"
                                style="animation-delay: 1s;"
                            >
                                <div class="slide__content slide__content--compact slide__content--about">
                                    <div class="slide__header">
                                        <h1
                                            class="slide__title slide__title--no-margin slide__title--light slide__title--extra-line-height animate__animated animate__pulse"
                                        >
                                            About
                                        </h1>
                                        <div
                                            class="overlay-close-btn animate__animated animate__fadeIn"
                                            on:keydown
                                            on:click={() => (isAbout = false)}
                                        >
                                            <Lottie jsonPath="/lottie/close_icon.json" loop={false} />
                                        </div>
                                    </div>
                                    <p class="slide-overlay__p">
                                        Term Finance is a web-based lender operating across the Caribbean.
                                    </p>
                                    <p class="slide-overlay__p">
                                        Using a sophisticated tech-stack, we’ve processed over one hundred thousand
                                        loans and eCommerce “buy now, pay later” transactions without ever meeting a
                                        customer face-to-face.
                                    </p>
                                    <p class="slide-overlay__p">
                                        Term Finance is owned by Trinidad & Tobago based PointWest Capital and First
                                        Citizens Bank.
                                    </p>
                                    <br />
                                    <Button
                                        primary
                                        medium
                                        onClick={() => {
                                            open(SupportModal, {}, supportModalOptions);
                                        }}
                                    >
                                        Contact us
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {#each SLIDES as slide}
                            {#if activeSlideId == slide.id}
                                <div class="slide animate__animated animate__fadeIn">
                                    <div class="slide__content">
                                        <h1 id="slide__title" class="slide__title">
                                            {slide.title}
                                        </h1>
                                        <div class="slide__subtitle animate__animated animate__fadeIn">
                                            {@html slide.subtitle}
                                        </div>
                                        <div class="slide__desc animate__animated animate__fadeIn">
                                            {@html slide.desc}
                                        </div>
                                        <div class="slide__cta animate__animated animate__fadeIn">
                                            {#if slide.cta.url || slide.cta.action}
                                                <Button
                                                    primary
                                                    medium
                                                    onClick={() => {
                                                        if (slide.cta.action) {
                                                            slide.cta.action();
                                                        } else {
                                                            window.open(slide.cta.url);
                                                        }
                                                    }}
                                                >
                                                    {slide.cta.label}
                                                </Button>
                                            {/if}
                                        </div>
                                    </div>
                                </div>
                            {/if}
                        {/each}
                        <div class="slide-icons">
                            {#each SLIDES as slide, i}
                                <div
                                    on:keypress
                                    on:click={() => changeSlide(slide.id)}
                                    class="slide-icons__icon"
                                    class:slide-icons__icon--active={activeSlideId == slide.id}
                                >
                                    <div class="slide-icons__label">{slide.titleShort}</div>
                                    <div class="slide-icons__animation">
                                        {#if slide.id != 4}
                                            <Lottie
                                                jsonPath={`/lottie/slide_${slide.id}_icon.json`}
                                                loop={activeSlideId == slide.id}
                                            />
                                        {:else}
                                            <span
                                                ><i
                                                    class="fa-duotone fa-regular fa-goal-net"
                                                    style="--fa-primary-color: #5426a3; --fa-secondary-color: #5426a3;"
                                                ></i></span
                                            >
                                        {/if}
                                    </div>
                                </div>
                                {#if i < SLIDES.length - 1}
                                    <div class="slide-icons__divider" />
                                {/if}
                            {/each}
                        </div>
                        <div class="split__progress" />
                        <div
                            bind:this={progressBarElement}
                            class="split__progress split__progress--fill"
                            style={`animation-duration: ${SLIDE_WAIT_TIME_SECONDS}s`}
                        />
                    </div>
                </div>
                <div class="split__container split__container--bg">
                    <div
                        style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; opacity: 0.5; z-index: 10;"
                    >
                        <Lottie jsonPath="/lottie/circle_bg.json" />
                    </div>
                    <div class="wrapper wrapper--justify-start wrapper--reverse">
                        <!-- <nav class="nav nav--reverse">
                            <div class="nav__links nav__links--small-gap">
                                <div class="nav__link">
                                    <Button
                                        secondaryInverse
                                        small
                                        disabled={isLogin}
                                        onClick={() => {
                                            open(SupportModal, {}, supportModalOptions);
                                        }}
                                    >
                                        Contact Us
                                    </Button>
                                </div>
                                <div class="nav__link">
                                    <Button
                                        primary
                                        small
                                        disabled={isLogin}
                                        onClick={() => {
                                            setTimeout(() => (isLogin = true), isAbout ? 1000 : 0);
                                            isAbout = false;
                                        }}
                                    >
                                        Log In / Register
                                    </Button>
                                </div>
                            </div>
                        </nav> -->
                    </div>
                    {#each SLIDES as slide, i}
                        <div
                            class="split__bg animate__animated"
                            class:animate__fadeIn={activeSlideId == slide.id}
                            class:animate__fadeOut={activeSlideId != slide.id}
                            style={`background-image: url(/images/bg_${slide.id}@2x.jpg); z-index: ${SLIDES.length - i};`}
                        />
                    {/each}
                </div>
            </div>
        </div>
    </Page>
</div>

<style lang="scss" type="text/scss">.root {
  background: #5123ad;
  height: 100vh;
  overflow: scroll;
}

.reveal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  background: #5123ad;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

.split {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #f5f4ff;
}
.split__container {
  overflow: hidden;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  position: relative;
  width: 60%;
  height: 100%;
  background: url("/images/login_bg.png") no-repeat center right;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.split__container--bg {
  display: none;
  overflow: hidden;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
  align-items: center;
  max-width: 800px;
  animation: fadeIn;
  animation-duration: 2s;
}
.split__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.split__bg--mobile {
  display: block;
}
.split__bg--overlay {
  display: block;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3);
}
@keyframes progress {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
.split__progress {
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  background: #dedbff;
}
.split__progress--fill {
  animation-name: progress;
  animation-direction: normal;
  animation-iteration-count: infinite;
  background: #5123ad;
  animation-timing-function: ease-in-out;
}
@media (min-width: 1050px) {
  .split__container--bg {
    display: flex;
  }
  .split__bg--mobile {
    display: none;
  }
  .split__bg--overlay {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 1.5625em;
  font-family: "Inter", sans-serif;
}
.wrapper--justify-start {
  justify-content: flex-start;
}

.nav {
  justify-content: space-between;
  position: relative;
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  min-height: 60px;
}
.nav--reverse {
  justify-content: flex-end;
}
.nav__logo {
  display: none;
  white-space: nowrap;
  min-width: 60px;
}
.nav__logo img {
  max-width: none;
}
.nav__logo--mobile {
  display: block;
}
.nav__logo--about {
  display: none;
}
.nav__links {
  display: none;
  -moz-column-gap: 2.1875em;
       column-gap: 2.1875em;
  color: #000;
}
.nav__links--small-gap {
  -moz-column-gap: 0.9375em;
       column-gap: 0.9375em;
}
.nav__links--white {
  color: #fff;
}
.nav__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: inherit;
  cursor: pointer;
}
.nav__link--mobile {
  margin-left: 0.9375em;
  display: flex;
}
.nav__mobile-links {
  display: flex;
  flex-flow: row nowrap;
}
@media (min-width: 650px) {
  .nav {
    justify-content: flex-start;
    flex-flow: row nowrap;
  }
  .nav__logo {
    margin-right: 3.125em;
  }
  .nav--reverse {
    justify-content: flex-end;
  }
  .nav__links {
    display: flex;
    color: #fff;
  }
  .nav__link--mobile {
    display: none;
  }
}
@media (min-width: 1050px) {
  .nav__logo {
    display: block;
  }
  .nav__logo--mobile {
    display: none;
  }
  .nav__link--mobile {
    display: none;
  }
  .nav__links {
    color: #000;
  }
  .nav__links--white {
    color: #fff;
  }
}

.slide {
  top: -4.6875em;
  padding: 0 0.625em;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}
.slide--center {
  justify-content: center;
  align-items: center;
  padding: 0;
}
.slide--no-top {
  top: 0em;
}
.slide__content {
  width: 100%;
}
.slide__content--compact {
  width: 100%;
  max-height: 95vh;
  max-width: 43.75em;
}
.slide__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5625em;
}
.slide__title {
  font-size: 3em;
  display: block;
  margin-bottom: 0.5em;
  font-weight: 700;
  line-height: 1;
  color: #f5f4ff;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}
.slide__title--light {
  color: #fff !important;
}
.slide__title--no-margin {
  margin-bottom: 0;
}
.slide__title--extra-line-height {
  line-height: 1.3;
}
.slide__subtitle {
  font-size: 1.75em;
  color: #f5f4ff;
  margin-bottom: 0.9375em;
  font-weight: 400;
  line-height: 1.1;
  animation-delay: 0.5s;
}
.slide__desc {
  display: none;
  font-size: 1.125em;
  color: #f5f4ff;
  margin-bottom: 1.25em;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  line-height: 1.4;
  animation-delay: 0.9s;
}
.slide__cta {
  animation-delay: 1.2s;
}
@media (min-width: 650px) {
  .slide {
    top: 0;
    max-width: 50em;
    padding: 0 2.5em;
  }
  .slide__content--compact {
    margin-top: 0;
  }
  .slide__title {
    margin-bottom: 0.1875em;
    font-size: 4.5em;
    line-height: 1.1;
  }
  .slide__subtitle {
    margin-bottom: 0.3125em;
    font-size: 2.625em;
  }
  .slide__desc {
    display: block;
    font-size: 1.5em;
  }
}
@media (min-width: 1050px) {
  .slide {
    max-width: none;
  }
  .slide__title {
    background: linear-gradient(119deg, rgb(81, 35, 173) 0%, rgb(140, 128, 185) 100%);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .slide__title--extra-line-height {
    line-height: 1.3;
  }
  .slide__title--light {
    color: #fff !important;
    background-clip: none;
    -webkit-text-fill-color: #fff;
  }
  .slide__subtitle {
    color: #000;
  }
  .slide__desc {
    color: #9f9fa5;
  }
}

.slide-icons {
  top: -7.8125em;
  justify-content: center;
  padding: 0;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
}
.slide-icons__icon {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  opacity: 1;
  cursor: pointer;
}
.slide-icons__icon--active {
  animation: bounceIn;
  animation-duration: 2s;
  opacity: 1;
}
.slide-icons__icon:hover {
  opacity: 1;
  animation: pulse;
  animation-duration: 0.25s;
}
.slide-icons__label {
  margin-bottom: 0.625em;
  font-size: 0.75em;
  color: #fff;
  white-space: nowrap;
}
.slide-icons__animation {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  padding: 0.3125em;
  background: rgba(245, 244, 255, 0.8);
  border: 1px solid #5123ad;
  border-radius: 50%;
}
.slide-icons__divider {
  position: relative;
  top: 8px;
  width: 50px;
  height: 2px;
  background: #fff;
  opacity: 0.25;
}
@media (min-width: 650px) {
  .slide-icons {
    top: 0;
  }
}
@media (min-width: 1050px) {
  .slide-icons {
    justify-content: flex-start;
    padding: 0 2.5em;
  }
  .slide-icons__icon {
    background: transparent;
    opacity: 0.5;
  }
  .slide-icons__icon--active {
    animation: bounceIn;
    animation-duration: 2s;
    opacity: 1;
  }
  .slide-icons__divider {
    background: #5123ad;
    opacity: 0.5;
  }
  .slide-icons__label {
    color: #9f9fa5;
  }
}

.slide-overlay {
  width: 100%;
  padding: 0 1.5625em;
  overflow-y: scroll;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: #5123ad;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  animation-duration: 0.25s;
}
.slide-overlay--login {
  background: url("/images/login_form_bg.png") no-repeat center;
  background-size: cover;
}
.slide-overlay--about {
  z-index: 2000;
  background: url("/images/about_us_bg.png") no-repeat center;
  background-size: cover;
}
.slide-overlay--football {
  z-index: 2000;
  background: url("/images/football_bg.png") no-repeat center;
  background-size: cover;
}
.slide-overlay__p {
  margin-bottom: 1.25em;
  line-height: 1.2;
  font-family: "Inter", sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  color: #f5f4ff;
}
@media (min-width: 650px) {
  .slide-overlay {
    overflow-y: auto;
  }
  .slide-overlay--about {
    z-index: 100;
  }
}
@media (min-width: 1050px) {
  .slide-overlay {
    width: 98%;
    padding: 0;
  }
  .slide-overlay--login {
    background: url("/images/login_form_bg.png") no-repeat center;
    background-size: cover;
  }
  .slide-overlay--about {
    background: url("/images/about_us_bg.png") no-repeat center;
    background-size: cover;
  }
}

.login-form {
  width: 100%;
}
.login-form__errors {
  margin: 1.5625em 0;
  font-family: "Inter", sans-serif;
  font-size: 1.5em;
  color: #fff;
}
.login-form__control {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.625em 0;
}
.login-form__actions {
  margin: 0.9375em 0;
}
.login-form__input {
  width: 100%;
  margin: 0;
  padding: 0.8125em 0.9375em;
  outline: none;
  border: none;
  border-radius: 1.375em;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.2);
  font-size: 1.25em;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}
.login-form__input::-moz-placeholder {
  color: #f5f4ff;
  opacity: 0.5;
}
.login-form__input::placeholder {
  color: #f5f4ff;
  opacity: 0.5;
}
.login-form__input:focus {
  animation: pulse;
  animation-duration: 0.25s;
}
.login-form__input:hover {
  background: rgba(255, 255, 255, 0.3);
}
.login-form__links {
  margin-top: 3.125em;
}
.login-form__link {
  font-size: 1.25em;
  font-weight: 300;
  color: #f5f4ff;
}
.login-form__link a,
.login-form__link div {
  display: inline-block;
  margin-bottom: 0.625em;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}
.login-form__link a:hover,
.login-form__link div:hover {
  animation: pulse;
  animation-duration: 0.25s;
  color: #cf57ff;
}
.login-form__loader {
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100px;
}

.overlay-close-btn {
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50px;
  cursor: pointer;
}
.overlay-close-btn:hover {
  animation: pulse;
  animation-duration: 0.25s;
  animation-iteration-count: infinite;
}</style>
